export const APP_NAME = 'Rezet ERP';

// must match the default value of api per_page pagination.
export const APP_TABLE_DEFAULT_SIZE = 15;

//export const API_BASE_URL = 'http://localhost:8000/api';
export const API_BASE_URL = 'https://api-erp.rezet.work/api';

//export const BASE_URL = 'http://localhost:3000';
export const BASE_URL = 'https://erp.rezet.work';

export const IM_BASE_URL = 'https://im.rezet.io/rezet';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const REPORT_ALLOWED_RANGE_DAYS = 4;

export const LOGO_SRC = process.env.PUBLIC_URL + '/images/rezet_logo_new.svg';
