import { Image } from 'antd';
import Link from './styled/Link';
import { APP_NAME, LOGO_SRC } from '../../constants/config';

const Logo = ({ onClick, buttonProps, ...imageProps }) => (
  <Link onClick={onClick}>
    <Image width={200} src={LOGO_SRC} preview={false} alt={APP_NAME} {...imageProps}/>
  </Link>
);

export default Logo;
