import moment from 'moment';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Upload} from 'antd';
import {domains, enlevels, offices, roles} from '../../constants/employees';
import { DATE_FORMAT } from '../../constants/config';
import useFormSubmit from '../../hooks/useFormSubmit';
import ImgCrop from 'antd-img-crop';
import React, {useCallback, useState} from "react";
import {useDispatchRequest} from "@redux-requests/react";
import {formatSelectInitialOption, hasAccessByRole} from "../../helpers";
import {searchChiefsOptions} from "../../store/employees/actions";
import SearchSelect from "../SearchSelect";
import {ADMIN, HR_L1} from "../../constants/roles";
import MaskedInput from "antd-mask-input";

const validationRules = {
  alternative_email: [
    { type: 'email' },
    { max: 255 }
  ],
  phone: [
    { required: true },
    { pattern: /^[0-9]{12}/ },
  ],
  domain: [
    { required: true }
  ],
  role: [
    { required: true }
  ],
  started_at: [
    { required: true },
  ],
  officer_id: [],
  chief_officer_id: [],
  office: [],
  en_level: [],
  en_score: []
};

const EmployeeUpdateForm = ({ onSubmit, onSuccess, onCancel, initialValues = null, role }) => {

  const [ form ] = Form.useForm();
  const dispatch = useDispatchRequest();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);
  const [avatar, setAvatar] = useState();
  const [imageUrl, setImageUrl] = useState(initialValues?.avatar);

  const initialOptionOfficer = formatSelectInitialOption(initialValues?.officer);
  const initialOptionChiefOfficer = formatSelectInitialOption(initialValues?.chief_officer);

  const handleSearchOfficer = useCallback(input => input.length > 1 && dispatch(searchChiefsOptions(input)), [ dispatch ]);
  const handleSearchChiefOfficer = useCallback(input => input.length > 1 && dispatch(searchChiefsOptions(input)), [ dispatch ]);
  const hasAccess = hasAccessByRole([ADMIN, HR_L1], role);

  const sendAvatar = (values) => {
    values.avatar = avatar;
    handleSubmit(values);
  }
  const beforeUpload = (info) => {

    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    getBase64(info, imageUrl => {
        setImageUrl(imageUrl)
        setAvatar(imageUrl)
      }
    );

    return false;
  }
  const onProfileImagePreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
        officer_id: initialValues?.officer?.id,
        chief_officer_id: initialValues?.chief_officer?.id,
        birthday: initialValues?.birthday && moment(initialValues.birthday),
        started_at: initialValues?.started_at && moment(initialValues.started_at),
        ended_at: initialValues?.ended_at && moment(initialValues.ended_at),
      }}
      onFinish={sendAvatar}
      layout='vertical'
    >
      {hasAccess && <Row gutter={16}>

        <Col span={6}>
          <Form.Item
            label='Domain'
            name='domain'
            rules={validationRules.domain}
          >
            <Select placeholder='Domain' options={domains} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
              label='Role'
              name='role'
              rules={validationRules.role}
          >
            <Select placeholder='Domain' options={roles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label='Phone'
            name='phone'
            rules={validationRules.phone}
          >
            <MaskedInput mask="111111111111" placeholder='Phone' placeholderChar=' '/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label='Alternative email'
            name='alternative_email'
            rules={validationRules.alternative_email}
          >
            <Input placeholder='Alternative email'/>
          </Form.Item>
        </Col>

      </Row>}

      <Form.Item
          label='Comment'
          name='comment'
      >
        <Input.TextArea placeholder='Comment' rows={3}/>
      </Form.Item>

      {hasAccess && <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label='Birthday'
              name='birthday'
              rules={validationRules.birthday}
          >
            <DatePicker style={{ width: '100%' }} placeholder='Birthday' format={DATE_FORMAT}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label='Start date'
              name='started_at'
              rules={validationRules.started_at}
          >
            <DatePicker style={{ width: '100%' }} placeholder='Start date' format={DATE_FORMAT}/>
          </Form.Item>
        </Col>
        { initialValues && (
          <Col span={8}>
            <Form.Item
                label='Termination date'
                name='ended_at'
                rules={validationRules.ended_at}
            >
              <DatePicker style={{ width: '100%' }} placeholder='Termination date' format={DATE_FORMAT}/>
            </Form.Item>
          </Col>
        ) }
      </Row>}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label='Officer'
            name='officer_id'
            rules={validationRules.officer_id}
          >
            <SearchSelect
              placeholder='Officer'
              onSearch={handleSearchOfficer}
              defaultOption={initialOptionOfficer}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='Chief Officer'
            name='chief_officer_id'
            rules={validationRules.chief_officer_id}
          >
            <SearchSelect
              placeholder='Chief Officer'
              onSearch={handleSearchChiefOfficer}
              defaultOption={initialOptionChiefOfficer}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='Office'
            name='office'
            rules={validationRules.office}
          >
            <Select placeholder='Office' options={offices} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label='English level'
            name='en_level'
            rules={validationRules.en_level}
          >
            <Select placeholder='English level' options={enlevels} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='English score'
            name='en_score'
            rules={validationRules.en_score}
          >
            <InputNumber  min={1} max={99} placeholder='English score' style={{ width: '100%' }}/>
          </Form.Item>
        </Col>
      </Row>
      {hasAccess && <Form.Item>
        <Space>
          <ImgCrop rotate>
            <Upload
                listType="picture-card"
                onPreview={onProfileImagePreview}
                showUploadList={false}
                beforeUpload={beforeUpload}
                maxCount={1}
                name="avatar"
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div style={{ marginTop: 8 }}>Upload</div>}
            </Upload>
          </ImgCrop>
        </Space>
      </Form.Item>}

      <Form.Item>
        <Space>
          <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default EmployeeUpdateForm