import React, {useCallback} from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {Table} from 'antd';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@redux-requests/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {DATE_TIME_FORMAT} from '../../../../constants/config';
import usePagination from '../../../../hooks/usePagination';
import CommentForm from '../../../../components/CommentForm';
import useSearchParams from '../../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../../utils/mapUrlSearchParams';
import {createComment, fetchEmployeeCommentsList} from '../../../../store/employees/actions';
import routes from "../../../../constants/routes";
import {formatDate} from '../../../../helpers';

const commentsColumns = [
  {
    title: 'Comment',
    dataIndex: 'content',
    key: 'content',
    fixed: 'left',
    render: comment => <ReactMarkdown remarkPlugins={[remarkGfm]}>{comment}</ReactMarkdown>
  },
  {
    title: 'Name',
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    width: 120,
    render: author => <Link to={routes.employees_view.path.replace(':id', author?.employee.id)}>{author?.employee.full_name}</Link>
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    width: 150,
    render: date => formatDate(moment(date), DATE_TIME_FORMAT)
  }
];

const Comments = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {data: comments, loading: commentsLoading, load} = useQuery({
    type: fetchEmployeeCommentsList,
    action: fetchEmployeeCommentsList,
    variables: [id, searchParams],
    requestKey: id + searchParams.toString(),
    autoLoad: true
  });

  const pagination = usePagination(comments?.meta);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const handleSubmit = useCallback(
    data => dispatch(createComment(id, data)).then(() => load()),
    [id, dispatch, searchParams, load]
  );

  return (
    <>
      <Table
        bordered
        rowKey='id'
        columns={commentsColumns}
        loading={commentsLoading}
        dataSource={comments?.data}
        onChange={handleChangeTable}
        pagination={pagination}
        size='small'
      />
      <CommentForm onSubmit={handleSubmit}/>
    </>
  )
}

export default Comments;